import { onMounted, ref } from "vue";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import {
  BloomEffect,
  EffectComposer,
  EffectPass,
  RenderPass,
} from "postprocessing";


var sceneRef = ref(null);
console.log(sceneRef.value);
var progress = ref(0);
var flag = ref(false);

// console.log(props.path);

let scene, renderer, camera, controls, spotLight, composer;

export function init(props, sceneRefl, progressl, flagl) {
    // Scene
    // console.log(sceneRefl.value)
    sceneRef = sceneRefl
    progress = progressl
    flag = flagl
    scene = new THREE.Scene();
    scene.background = new THREE.Color(0x212529);
    console.log(sceneRef);
    // Renderer
    renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(sceneRef.value.clientWidth, sceneRef.value.clientHeight);
    renderer.toneMapping = THREE.ReinhardToneMapping;
    renderer.toneMappingExposure = 3;
    renderer.shadowMap.enabled = true;
    sceneRef.value.appendChild(renderer.domElement);

    // Camera
    const aspect = sceneRef.value.clientWidth / sceneRef.value.clientHeight;
    camera = new THREE.PerspectiveCamera(60, aspect, 0.01, 1000);
    camera.position.set(0, 3, 5);

    // Camera Controls
    controls = new OrbitControls(camera, renderer.domElement);
    controls.update();

    // Light
    const hemiLight = new THREE.HemisphereLight(0xffeeb1, 0x080820, 2);
    scene.add(hemiLight);

    spotLight = new THREE.SpotLight(0xffa95c, 4);
    spotLight.castShadow = true;
    spotLight.shadow.bias = -0.0001;
    spotLight.shadow.mapSize.width = 10000;
    spotLight.shadow.mapSize.height = 10000;
    scene.add(spotLight);

    // Post Processing
    composer = new EffectComposer(renderer);
    composer.addPass(new RenderPass(scene, camera));

    const effectPass = new EffectPass(
        camera,
        new BloomEffect({
            intensity: 3,
            luminanceThreshold: 0.8,
            width: 100,
            height: 100,
        })
    );
    composer.addPass(effectPass);

    // Loading Manager
    const manager = new THREE.LoadingManager();
    manager.onProgress = (url, itemsLoaded, itemsTotal) => {
        progress.value = (itemsLoaded / itemsTotal) * 100;
        if ((itemsLoaded / itemsTotal) * 100 === 100) {
            setTimeout(() => {
                flag.value = true;
            }, 1000);
        }
    };

    // Loader
    const loader = new GLTFLoader(manager);
    loader.load(props, (result) => {
        const model = result.scene.children[0];
        model.scale.set(1, 1, 1);
        model.traverse((n) => {
            if (n.isMesh) {
                n.castShadow = true;
                n.receiveShadow = true;
                if (n.material.map) n.material.map.anisotropy = 100;
            }
        });
        scene.add(model);
        animate();
        console.log('走完')
    });
}

const animate = () => {
    requestAnimationFrame(animate);
    controls.update();
    spotLight.position.set(
        camera.position.x + 5,
        camera.position.y + 5,
        camera.position.z - 5
    );
    // renderer.render(scene, camera);
    composer.render();
}

export function onCanvasResize() {
    camera.aspect = sceneRef.value.clientWidth / sceneRef.value.clientHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(sceneRef.value.clientWidth, sceneRef.value.clientHeight);
    composer.setSize(sceneRef.value.clientWidth, sceneRef.value.clientHeight);
}

onMounted(() => {
    window.addEventListener("resize", onCanvasResize, false);
    init();
})

export function re() {
    return {
    sceneRef,
    progress,
    flag,
}}